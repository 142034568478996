import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Section, Strong } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Mocha Delight Coffee House
			</title>
			<meta name={"description"} content={"Де кожен ковток викликає радість"} />
			<meta property={"og:title"} content={"Mocha Delight Coffee House"} />
			<meta property={"og:description"} content={"Де кожен ковток викликає радість"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66583a008e2e8e00217c3706/images/2-2.jpg?v=2024-05-30T11:46:42.484Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66583a008e2e8e00217c3706/images/2-2.jpg?v=2024-05-30T11:46:42.484Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66583a008e2e8e00217c3706/images/2-2.jpg?v=2024-05-30T11:46:42.484Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66583a008e2e8e00217c3706/images/2-2.jpg?v=2024-05-30T11:46:42.484Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66583a008e2e8e00217c3706/images/2-2.jpg?v=2024-05-30T11:46:42.484Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66583a008e2e8e00217c3706/images/2-2.jpg?v=2024-05-30T11:46:42.484Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66583a008e2e8e00217c3706/images/2-2.jpg?v=2024-05-30T11:46:42.484Z"} />
		</Helmet>
		<Components.Contact />
		<Components.Nav />
		<Components.Hero>
			<Override slot="button" href="/services">
				Меню
			</Override>
			<Override slot="text1">
				Де кожна мить - насолода
			</Override>
			<Override slot="text">
				Про нас
			</Override>
			<Override slot="text2">
				Ласкаво просимо до кав'ярні Mocha Delight! Наша подорож почалася з простої мрії: створити місце, де любителі кави зможуть знайти не просто напій, а й отримати враження. Ми хотіли створити притулок для тих, хто цінує мистецтво приготування кави та радість смакувати кожен ковток. Наша відданість якості та співтовариству є основою всього, що ми робимо з першого дня.
			</Override>
		</Components.Hero>
		<Section padding="120px 0 130px 0" md-padding="80px 0 80px 0" quarkly-title="Reviews-13">
			<Override slot="SectionContent" md-padding="0px 0 0px 0" />
			<Text margin="0px 0px 2rem 0px" color="--darkL2" font="normal 600 46px/1.2 --fontFamily-serifGaramond" text-align="center">
				Про нас
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				padding="0 0 0 0"
				border-radius="8px"
				margin="0px 0 0 0"
				display="flex"
				flex-direction="row"
				justify-content="space-between"
				lg-margin="0px 0 0 0px"
				md-flex-direction="column"
				lg-flex-direction="column"
				lg-padding="0 0 0 0"
			>
				<Box
					min-width="100px"
					min-height="100px"
					padding="0px 70px 0px 0"
					width="50%"
					display="flex"
					flex-direction="column"
					justify-content="center"
					md-width="100%"
					lg-width="100%"
					lg-margin="0px 0px 0 0px"
					lg-padding="0px 0px 0px 0"
					sm-padding="0 0px 0px 0"
					margin="0px 0 0px 0px"
					align-items="center"
					align-content="center"
				>
					<Box
						min-width="100px"
						min-height="100px"
						border-width="0 0 0 2px"
						border-style="solid"
						border-color="#333b41"
						padding="0px 0px 0px 25px"
						margin="0px 0px 40px 0px"
						lg-margin="0px 0px 30px 0px"
					>
						<Text margin="0px 0px 0 0px" font="--base" color="#333b41">
							У Mocha Delight ми ретельно відбираємо кавові зерна від найкращих виробників у всьому світі. Наші досвідчені обсмажувачі виявляють унікальний смак кожного зерна, забезпечуючи ідеальне поєднання аромату та смаку в кожній чашці. Наші бариста не просто вміють готувати каву, вони пристрасно хочуть поділитися своєю любов’ю до неї з кожним клієнтом, який проходить через наші двері.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					display="flex"
					flex-direction="column"
					align-items="center"
					justify-content="center"
					lg-width="100%"
					lg-order="-1"
					lg-margin="0px 0px 40px 0px"
					sm-margin="0px 0px 40px 0px"
				>
					<Image
						src="https://uploads.quarkly.io/66583a008e2e8e00217c3706/images/3-4.jpg?v=2024-05-30T11:46:42.488Z"
						display="block"
						margin="0px 0 0px 0px"
						width="auto"
						height="500px"
						border-radius={0}
						border-color="#b0b7bb"
						object-fit="cover"
						sm-height="350px"
						max-width="100%"
						srcSet="https://smartuploads.quarkly.io/66583a008e2e8e00217c3706/images/3-4.jpg?v=2024-05-30T11%3A46%3A42.488Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66583a008e2e8e00217c3706/images/3-4.jpg?v=2024-05-30T11%3A46%3A42.488Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66583a008e2e8e00217c3706/images/3-4.jpg?v=2024-05-30T11%3A46%3A42.488Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66583a008e2e8e00217c3706/images/3-4.jpg?v=2024-05-30T11%3A46%3A42.488Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66583a008e2e8e00217c3706/images/3-4.jpg?v=2024-05-30T11%3A46%3A42.488Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66583a008e2e8e00217c3706/images/3-4.jpg?v=2024-05-30T11%3A46%3A42.488Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66583a008e2e8e00217c3706/images/3-4.jpg?v=2024-05-30T11%3A46%3A42.488Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
		</Section>
		<Section padding="0 0 130px 0" md-padding="80px 0 80px 0" quarkly-title="Reviews-13">
			<Override slot="SectionContent" md-padding="0px 0 0px 0" />
			<Box
				min-width="100px"
				min-height="100px"
				padding="0 0 0 0"
				border-radius="8px"
				margin="0px 0 0 0"
				display="flex"
				flex-direction="row"
				justify-content="space-between"
				lg-margin="0px 0 0 0px"
				md-flex-direction="column"
				lg-flex-direction="column"
				lg-padding="0 0 0 0"
			>
				<Box
					min-width="100px"
					min-height="100px"
					padding="0px 70px 0px 0"
					width="50%"
					display="flex"
					flex-direction="column"
					justify-content="center"
					md-width="100%"
					lg-width="100%"
					lg-margin="0px 0px 0 0px"
					lg-padding="0px 0px 0px 0"
					sm-padding="0 0px 0px 0"
					margin="0px 0 0px 0px"
					order="1"
				>
					<Box
						min-width="100px"
						min-height="100px"
						border-width="0 2px 0 0"
						border-style="solid"
						border-color="#333b41"
						padding="0px 25px 0px 0"
						margin="0px 0px 40px 0px"
						lg-margin="0px 0px 30px 0px"
					>
						<Text margin="0px 0px 0 0px" font="--base" color="#333b41" text-align="right">
							У кав’ярні Mocha Delight ми пропонуємо не просто каву. Наша затишна та приваблива атмосфера створена для того, щоб ви відчували себе як вдома. Від зручного сидіння до теплого освітлення, кожна деталь продумана, щоб створити розслаблюючу атмосферу. Незалежно від того, чи приїхали ви тут, щоб поспілкуватися з друзями, попрацювати над проектом або просто провести час на самоті, Mocha Delight — ідеальне місце.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					display="flex"
					flex-direction="column"
					align-items="center"
					justify-content="center"
					lg-width="100%"
					lg-order="-1"
					lg-margin="0px 0px 40px 0px"
					sm-margin="0px 0px 40px 0px"
				>
					<Image
						src="https://uploads.quarkly.io/66583a008e2e8e00217c3706/images/2-3.jpg?v=2024-05-30T11:46:42.471Z"
						display="block"
						margin="0px 0 0px 0px"
						width="auto"
						height="500px"
						border-radius={0}
						border-color="#b0b7bb"
						object-fit="cover"
						sm-height="350px"
						max-width="100%"
						srcSet="https://smartuploads.quarkly.io/66583a008e2e8e00217c3706/images/2-3.jpg?v=2024-05-30T11%3A46%3A42.471Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66583a008e2e8e00217c3706/images/2-3.jpg?v=2024-05-30T11%3A46%3A42.471Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66583a008e2e8e00217c3706/images/2-3.jpg?v=2024-05-30T11%3A46%3A42.471Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66583a008e2e8e00217c3706/images/2-3.jpg?v=2024-05-30T11%3A46%3A42.471Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66583a008e2e8e00217c3706/images/2-3.jpg?v=2024-05-30T11%3A46%3A42.471Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66583a008e2e8e00217c3706/images/2-3.jpg?v=2024-05-30T11%3A46%3A42.471Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66583a008e2e8e00217c3706/images/2-3.jpg?v=2024-05-30T11%3A46%3A42.471Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0px 60px 0px">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="24px 0px 16px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				align-self="center"
			>
				<Text
					margin="0px 0px 48px 0px"
					color="--greyD3"
					font="--base"
					lg-text-align="center"
					lg-width="100%"
				>
					Окрім широкого асортименту кавових напоїв, ми також пропонуємо вибір свіжої випічки та закусок. Кожна позиція в нашому меню створена з такою самою відданістю якості, як і наша кава. Ми віримо в те, щоб забезпечити повний досвід, коли кожне відвідування - це задоволення для ваших почуттів.
				</Text>
				<Text
					margin="0px 0px 48px 0px"
					color="--greyD3"
					font="--base"
					lg-text-align="center"
					lg-width="100%"
				>
					<Strong>
						Що нас відрізняє
						<br />
					</Strong>
					Виняткова якість: Ми отримуємо каву в зернах від кращих виробників і обсмажуємо її до досконалості, забезпечуючи насичений і ароматний досвід з кожною чашкою.
Кваліфіковані бариста: наші бариста захоплюються кавою та володіють майстерністю пивоваріння, гарантуючи, що кожен напій буде створений досконалістю.
Затишна атмосфера: Наша кав’ярня створена як затишне та привітне місце, де ви можете розслабитися та насолодитися кавою.
Смачна випічка: Ми пропонуємо різноманітну свіжоспечену випічку та закуски, виготовлені з такою самою відданістю якості, як і наша кава.
				</Text>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="flex-end"
				overflow-y="hidden"
				overflow-x="hidden"
				lg-width="100%"
				padding="0px 0px 0px 16px"
				align-items="center"
				lg-justify-content="center"
				lg-padding="0px 0px 0px 0px"
			>
				<Image
					src="https://uploads.quarkly.io/66583a008e2e8e00217c3706/images/3-2.jpg?v=2024-05-30T11:46:42.504Z"
					max-width="100%"
					transform="translateY(10px)"
					transition="transform 0.5s ease-in-out 0s"
					hover-transform="translateY(0px)"
					srcSet="https://smartuploads.quarkly.io/66583a008e2e8e00217c3706/images/3-2.jpg?v=2024-05-30T11%3A46%3A42.504Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66583a008e2e8e00217c3706/images/3-2.jpg?v=2024-05-30T11%3A46%3A42.504Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66583a008e2e8e00217c3706/images/3-2.jpg?v=2024-05-30T11%3A46%3A42.504Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66583a008e2e8e00217c3706/images/3-2.jpg?v=2024-05-30T11%3A46%3A42.504Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66583a008e2e8e00217c3706/images/3-2.jpg?v=2024-05-30T11%3A46%3A42.504Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66583a008e2e8e00217c3706/images/3-2.jpg?v=2024-05-30T11%3A46%3A42.504Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66583a008e2e8e00217c3706/images/3-2.jpg?v=2024-05-30T11%3A46%3A42.504Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Components.Services />
		<Components.Nav />
		<Components.Contact />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"66329762fdcae00021e1d75a"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});